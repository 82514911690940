import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';

import green from './assets/images/yellow.png'
import blue from './assets/images/blue.png'
import pink from './assets/images/pink.png'
import yellow from './assets/images/yellow-1.png'
import './styles.scss'

const PayButtonBenefits: React.FC = () => {
  
    return (
      <>
        <section className="container">
          <div className="row">
            <h1 className="buttonPay-benefits-title">
              <FormattedMessage id="button_pay.benefits.title"/>
            </h1>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 order-1">
              <div className="card card-benefit-space">
                <div className="card-body">
                  <img src={green} className="benefits-card-img mt-sm-card"/>
                  <h4 className="benefits-card-title text-primary"><FormattedMessage id="button_pay.benefits.green.title" /></h4>
                  <p className="lead"><FormattedMessage id="button_pay.benefits.green.description" /></p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 order-2 mt-sm-card mt-md-0">
              <div className="card card-benefit-space">
                <div className="card-body">
                  <img src={blue} className="benefits-card-img"/>
                  <h4 className="benefits-card-title text-primary"><FormattedMessage id="button_pay.benefits.blue.title" /></h4>
                  <p className="lead"><FormattedMessage id="button_pay.benefits.blue.description" /></p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-md-3">
            <div className="col-md-6 col-sm-12 order-1 mt-sm-card">
              <div className="card card-benefit-space">
                <div className="card-body">
                  <img src={pink} className="benefits-card-img"/>
                  <h4 className="benefits-card-title text-primary"><FormattedMessage id="button_pay.benefits.pink.title" /></h4>
                  <p className="lead"><FormattedMessage id="button_pay.benefits.pink.description" /></p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 order-2 mt-sm-card">
              <div className="card card-benefit-space">
                <div className="card-body">
                  <img src={yellow} className="benefits-card-img"/>
                  <h4 className="benefits-card-title text-primary"><FormattedMessage id="button_pay.benefits.yellow.title" /></h4>
                  <p className="lead"><FormattedMessage id="button_pay.benefits.yellow.description" /></p>
                </div>
              </div>
            </div>
          </div>

        </section>
      </>
    )
}

export default PayButtonBenefits