import React from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../../data/defaultFormat'

import './styles.scss'

import IconPlug from './assets/images/plug.inline.svg'
import IconPencil from './assets/images/pencil.inline.svg'
import IconTablet from './assets/images/tablet.inline.svg'
import IconShield from './assets/images/shield.inline.svg'
import IconDollar from './assets/images/dollar.inline.svg'

const PayButtonFeatures: React.FC = () => {

return (
  <>
    <section className="py-layout-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-primary mt-5">
              <FormattedMessage id="button_pay.features.title"
                values={defaultFormatValues}
              />
            </h2>
            <p className="lead mt-5">
              <FormattedMessage id="button_pay.features.description"
                values={defaultFormatValues}
              />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-1 col-1 d-flex justify-content-center pt-layout-2">
                <IconPlug className="side-icon"  />
              </div>
              <div className="col-md-10 col-10">
                <h3 className="text-primary mt-5">
                  <FormattedMessage id="button_pay.features.feature_1.title"
                    values={defaultFormatValues}
                  />
                </h3>
                <p className="lead mt-5">
                  <FormattedMessage id="button_pay.features.feature_1.description"
                    values={defaultFormatValues}
                  />
                </p>
              </div>
            </div>
            <div className="row">
            <div className="col-md-1 col-1 d-flex justify-content-center pt-layout-2">
              <IconPencil  className="side-icon" />
            </div>
            <div className="col-md-10 col-10">
              <h3 className="text-primary mt-5">
                <FormattedMessage id="button_pay.features.feature_2.title"
                  values={defaultFormatValues}
                />
              </h3>
              <p className="lead mt-5">
                <FormattedMessage id="button_pay.features.feature_2.description"
                  values={defaultFormatValues}
                />
              </p>
            </div>
            </div>
            <div className="row">
              <div className="col-md-1 col-1 d-flex justify-content-center pt-layout-2">
                <IconTablet className="side-icon" />
              </div>
              <div className="col-md-10 col-10">
              <h3 className="text-primary mt-5">
                <FormattedMessage id="button_pay.features.feature_3.title"
                  values={defaultFormatValues}
                />
              </h3>
              <p className="lead mt-5">
                <FormattedMessage id="button_pay.features.feature_3.description"
                  values={defaultFormatValues}
                />
              </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-1 col-1 d-flex justify-content-center pt-layout-2">
                <IconShield className="side-icon" />
              </div>
              <div className="col-md-10 col-10">
                <h3 className="text-primary mt-5">
                  <FormattedMessage id="button_pay.features.feature_4.title"
                    values={defaultFormatValues}
                  />
                </h3>
                <p className="lead mt-5">
                  <FormattedMessage id="button_pay.features.feature_4.description"
                    values={defaultFormatValues}
                  />
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-1 col-1 d-flex justify-content-center pt-layout-2">
                <IconDollar className="side-icon" />
              </div>
              <div className="col-md-10 col-10">
                <h3 className="text-primary mt-5">
                  <FormattedMessage id="button_pay.features.feature_5.title"
                    values={defaultFormatValues}
                  />
                </h3>
                <p className="lead mt-5">
                  <FormattedMessage id="button_pay.features.feature_5.description"
                    values={defaultFormatValues}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)
}

export default PayButtonFeatures
