import * as React from 'react';

import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

import { LocaleTypes } from '../../data/locales';
import PayButtonHero from '../../components/Features/PayButton/PayButtonHero/PayButtonHero';
import PayButtonBenefits from '../../components/Features/PayButton/PayButtonBenefits/PayButtonBenefits';
import PayButtonFeatures from '../../components/Features/PayButton/PayButtonFeatures/PayButtonFeatures';
import PayButtonRocket from '../../components/Features/PayButton/PayButtonRocket/PayButtonRocket';
import PanelAnimated from '../../components/PanelAnimated/PanelAnimated';

interface ButtonPayPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const ButtonPayPage: React.FC<ButtonPayPageProps> = ({ pathContext, location }) => {


  const content = () => (
    <>
      <SEO titleId="button_pay.metaTitle" descriptionId="button_pay.metaDescription" />
      <PayButtonHero />
      <PayButtonBenefits/>
      <PayButtonFeatures />
      <PanelAnimated panelName='bp' />
      <PayButtonRocket />
      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default ButtonPayPage;
