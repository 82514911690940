import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';

import SalesButton from '../../../SalesButton/SalesButton';

import HeroImg from './assets/images/hero.svg';

import './styles.scss';

const PayButtonHero: React.FC = ({ children }) => (
  <section className="hero overflow-visible">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-2 order-lg-1">
          <h1 className="text-primary display-4">
            <FormattedMessage id="button_pay.hero.title"/>
          </h1>
          <h2 className="text-primary ">
            <FormattedMessage id="button_pay.hero.subtitle1"/>
          </h2>
          <p className="lead text-dark-gray-3 mt-5">
            <FormattedMessage id="button_pay.hero.subtitle"
              values={defaultFormatValues}
            />
          </p>
          <div className="button-responsive-row mt-2">
            <SalesButton positionTag="top">
              <FormattedMessage id="buttons.sales"/>
            </SalesButton>
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 text-md-center text-lg-right">
          <img className="paybutton__hero-img" src={HeroImg} alt="" />
        </div>
      </div>
    </div>
  </section>
);

export default PayButtonHero;
